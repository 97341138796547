import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Autocomplete, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@mui/material';
import CustomSnackbar from '../Components/CustomSnackbar';
import StepriseTextField from '../Components/StepriseTextField';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ProcessingDialog from '../Components/ProcessingDialog';
import ResponsiveTable from '../Components/ResponsiveTable';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Papa from 'papaparse';
import {isValidEmail} from '../utils/Validation';
import SearchIcon from '@mui/icons-material/Search';
import StepriseAutocompleteBox from '../Components/StepriseAutocompleteBox';
import exportToExcel from "../utils/exportToExcel";

export default function ClientManagment() {
  const [duplicateDialogData, setDuplicateDialogData] = useState([]);
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);

  const [touched, setTouched] = useState({
    contactPersonMobile: false,
    contactPersonEmail: false
  });

  const [userDtls, setUserDtls] = useState([]);
  const [clientTbl, setClientTbl] = useState([]);
  const [contactPersonTbl, setContactPersonTbl] = useState([]);
  const [isItemBtn, setIsItemBtn] = useState(true);
  const [isSubmitBtn, setIsSubmitBtn] = useState(true);
  const [responseSeverity, setResponseSeverity] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [pins, setPins] = useState([]);
  const [pinInputValue, setPinInputValue] = useState('');
  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

  const [clientCsvList, setClientCsvList] = useState([]);
  const [isCsvReady, setIsCsvReady] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /* initialize the form variable */
  const [clientData, setClientData] = useState({
    _id:{value:''},
    clientId:{ value:'', error:false, errorMessage: ''},
    clientName:{ value:'', error:false, errorMessage: 'You must enter client name' },
    address:{ value:'', error:false, errorMessage: 'You must enter client address' },
    pinCode:{ value:'', error:false, errorMessage: 'You must enter pin' },
    city:{ value:'', error:false, errorMessage: 'You must enter pin' },
    state:{ value:'', error:false, errorMessage: 'You must enter state' },
    contactPersonName:{ value:'', error:false, errorMessage: 'You must enter name' },
    contactPersonMobile:{ value:'', error:false, errorMessage: 'You must enter mobile' },
    contactPersonEmail:{ value:'', error:false, errorMessage: 'You must enter email' },
    status: { value:'Draft', error:false, errorMessage: '' },
    isActive:{ value:'Y', error:false },
    createdBy:{ value: null, error:false },
    createdDate:{ value: null, error:false },
    lastModifiedBy:{ value: null, error:false },
    lastModifiedDate:{ value:null, error:false }
  });

  const [clientContactData, setClientContactData] = useState({
    _id:{value:''},
    clientContactId: { value:'', error:false, errorMessage: '' },
    contactPersonName:{ value:'', error:false, errorMessage: 'You must enter name' },
    contactPersonMobile:{ value:'', error:false, errorMessage: 'You must enter client mobile' },
    contactPersonEmail:{ value:'', error:false, errorMessage: 'You must enter email' },
    isActive:{ value:'Y', error:false },
    createdBy:{ value: null, error:false },
    createdDate:{ value: null, error:false },
    lastModifiedBy:{ value: null, error:false },
    lastModifiedDate:{ value:null, error:false }
  });

  const [filterClientInputValue, setFilterClientInputValue] = useState('');
  const [filterClients, setFilterClients] = useState([]);

  const [filterData, setFilterData] = useState({
    client: { value: null, error:false, errorMessage: '' },
  });

  /* header names for the table columns */ 
  const clientTblHeader = [
    { accessorKey: 'clientName', name: 'Client Name'},
    { accessorKey: 'address', name: 'Address'},
    { accessorKey: 'pinCode', name: 'Pin Code'},
    {accessorKey: 'state', name: 'State'},
    {accessorKey: 'city', name: 'City'},
    { accessorKey: 'contactPersonName', name: 'Contact Person'},
    {accessorKey: 'contactPersonMobile', name: 'Mobile'},
    {accessorKey: 'contactPersonEmail', name: 'Email'}
  ];

  /* while loding the page */
  useEffect(() => {
    const token = localStorage.getItem('token');
    const dcrptData = jwtDecode(token);

    setUserDtls(dcrptData);
    fetchClients();
  }, []);

  /* fetchOptions call when type pinCode */
  const fetchClients = async () => {
    let client = filterData.client.value || null;
    try {
      const validToken = localStorage.getItem('token');
      setClientTbl([]);
      
      const response = await axios.get(`${BASE_API_URL}/api/client/getallclients/${client}`, {
        headers: {
          Authorization: `${validToken}`
        }
      });
      setClientTbl(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  /* input handle change */
  const handleChangeForClientData = (event) => {
    const { name, value } = event.target;
    let error = false;
    let errorMessage = '';

    if (name === 'contactPersonEmail') {
      if (!isValidEmail(value)) {
        error = true;
        errorMessage = 'Invalid email format';
      }
    }

    setClientData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], value, error, errorMessage}
    }));
  };


  /* fetchOptions call when type pinCode */
  const fetchPins = async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/statepinmap/getStatePinMapByPrefix/${value}`);
      setPins(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  /* Requied for pincode autocomplete */
  const handlePinInputChange = (event, value) => {
    setPinInputValue(value);
    setClientData(prevData => ({
      ...prevData,
      pinCode: {
        ...prevData.pinCode,
        value: value,
        error: !value,
        errorMessage: !value ? 'You must enter a pincode' : ''
      }
    }));
    if (value) {
      fetchPins(value);
    } else {
      setPins([]);
    }
  };


  /* Requied for pincode autocomplete */
  const handlePinAutocompleteChange = (event, selectedPincode) => {
    const selectedOption = pins.find(item => item.pincode === selectedPincode);
    
    if (selectedOption) {
      setClientData(prevData => ({
        ...prevData,
        pinCode: {
          ...prevData.pinCode,
          value: selectedOption.pincode,
          error: false
        },
        city: {
          ...prevData.city,
          value: selectedOption.district
        },
        state: {
          ...prevData.state,
          value: selectedOption.stateName
        },
      }));
    } else {
      console.error('Selected pincode data not found in options');
    }
  };

  /* validation for client data */
  const validateClientData = () => {
    const requiredFields = ['clientName', 'address', 'pinCode', 'contactPersonName', 'contactPersonMobile', 'contactPersonEmail'];
    let isValid = true;
  
    requiredFields.forEach(field => {
      if (!clientData[field].value) {
        setClientData(prevState => ({
          ...prevState,
          [field]: { ...prevState[field], error: true },
        }));
        isValid = false;
      }
    });
  
    return isValid;
  };
  

  /* fetch client contact data by client id */
  const fetchClientContactDataByClientId = async (value) => {
    const _id = value;
    try {
      const response = await axios.get(`${BASE_API_URL}/api/client/getclient/${_id}`);
      const data = response.data;

      setContactPersonTbl([]);

      clientData._id.value = data._id;
      clientData.clientId.value = data.clientId;

      clientData.clientName.value = data.clientName;
      clientData.address.value = data.address;
      clientData.pinCode.value = data.pinCode;
      clientData.city.value = data.city;
      clientData.state.value = data.state;
      clientData.status.value = data.status;

      clientData.isActive.value = data.isActive;
      clientData.createdBy.value = data.createdBy;
      clientData.createdDate.value = data.createdDate;
      clientData.lastModifiedBy.value = data.lastModifiedBy;
      clientData.lastModifiedDate.value = data.lastModifiedDate;

      setProcessing(false);
      fetchClients();
    } catch (error) {
      console.error('Error while calling fetchClientContactDataByClientId', error);
    }
  }

  /* on click submit btn */
  const handleSubmit = async (e) => {
    e.preventDefault();
    let responseSeverity = '';
    let responseMessage = '';
    let loginId = userDtls.user_ID;

    try {
      if(validateClientData()){
        setProcessing(true);
        const validToken = localStorage.getItem('token');
        const finalDataToSend = {
          _id: clientData._id.value,
          clientName: clientData.clientName.value,
          address: clientData.address.value,
          pinCode: clientData.pinCode.value,
          city: clientData.city.value,
          state: clientData.state.value,
          contactPersonName: clientData.contactPersonName.value,
          contactPersonMobile: clientData.contactPersonMobile.value,
          contactPersonEmail: clientData.contactPersonEmail.value,
          status: clientData.status.value,
          isActive: clientData.isActive.value,
          createdBy: loginId,
          lastModifiedBy: loginId
        }

        const response = await axios.post(`${BASE_API_URL}/api/client/create`, finalDataToSend, {
          headers: {
            Authorization: `${validToken}`
          }
        });
        
        let data = response.data;
        let msg = data.message;

        if(data.status === 'success'){
          responseSeverity = 'success';
          responseMessage = `${msg}`;
          setProcessing(false);

          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);

          handleReload();
        }else if(data.status === 'duplicate'){
          responseSeverity = 'error';
          responseMessage = `${msg}`;
          setProcessing(false);

          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);

          handleReload();
        }
      }else{
        responseSeverity = 'error';
        responseMessage = 'Validation error please check again before add item';

        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setOpen(true);
        return;
      }
    } catch (error) {
      console.error('Error while calling addContactPerson', error);
    }
  }


  /** reset the form after any operation */
  const handleReload = () => {
    setClientData({
      _id:{value:''},
      clientId:{ value:'', error:false, errorMessage: ''},
      clientName:{ value:'', error:false, errorMessage: 'You must enter client name' },
      address:{ value:'', error:false, errorMessage: 'You must enter client address' },
      pinCode:{ value:'', error:false, errorMessage: 'You must enter pin' },
      city:{ value:'', error:false, errorMessage: 'You must enter pin' },
      state:{ value:'', error:false, errorMessage: 'You must enter state' },
      contactPersonName:{ value:'', error:false, errorMessage: 'You must enter name' },
      contactPersonMobile:{ value:'', error:false, errorMessage: 'You must enter mobile' },
      contactPersonEmail:{ value:'', error:false, errorMessage: 'You must enter email' },
      status: { value:'Draft', error:false, errorMessage: '' },
      isActive:{ value:'Y', error:false },
      createdBy:{ value: null, error:false },
      createdDate:{ value: null, error:false },
      lastModifiedBy:{ value: null, error:false },
      lastModifiedDate:{ value:null, error:false }
    });

    setPinInputValue('');
    setPins([]);

    fetchClients();
    setIsSubmitBtn(true);
  }

  /* on click item edit icon */
  const handleItemEdit = (row) => {
    setProcessing(true);
    let itemData = row;
    try {
      if(itemData){
        const updatedData = { ...clientContactData };

        Object.keys(clientContactData).forEach((key) => {
          let value = row[key];
          if (row[key] !== undefined) {
            updatedData[key] = {
              ...clientContactData[key],
              value: value
            };
          }
        });

        setClientContactData(updatedData);
        setIsItemBtn(false);
      }
    } catch (error) {
      console.error('Error while calling handleItemDelete :', error);
    }finally{
      setProcessing(false);
    }
  }

  /* on click item delete icon */
  const handleItemDelete = async (row) => {
    try {
        const clientId = clientData._id.value;
        const clientContactId = row._id;

        if(clientId && clientContactId){
            const response = await axios.delete(`${BASE_API_URL}/api/client/delete/${clientId}/${clientContactId}`);

            const data = response.data;

            if(data.status === 'success'){
              fetchClientContactDataByClientId(clientId);
            }
        }
    } catch (error) {
      console.error('Error while calling handleItemDelete :', error);
    }
  }

  /* on click edit icons */
  const handleEdit = (row) => {
    setProcessing(true);
    let data = row.original;
    try {
      if(data){
        const updatedData = { ...clientData };

        Object.keys(clientData).forEach((key) => {
          let value = row.original[key];

          if (key === 'pinCode' && row.original[key] !== undefined) {
            value = row.original[key];
            setPinInputValue(value);
            setPins([{ pincode: value }]);
          }
          
          if (row.original[key] !== undefined) {
            updatedData[key] = {
              ...clientData[key],
              value: value
            };
          }
        });

        setClientData(updatedData);
        setIsSubmitBtn(false);
      }
    } catch (error) {
      console.error('Error while calling handleEdit :', error);
    }finally{
      setProcessing(false);
    }
  }

  const deleteAction = () => {

  }

  /* on click toggle icon */
  const toggleIsActive = async(row) => {
    let responseSeverity = '';
    let responseMessage = '';
    let msg = '';
    setProcessing(true);
    let _id = row.original._id;
    const currentIsActive = row.original.isActive;

    try {
      const validToken = localStorage.getItem('token');

      if(currentIsActive === 'Y'){
        row.original.isActive = 'N';
        msg = 'Selected Client is In-Active Now';
      }else {
        row.original.isActive = 'Y';
        msg = 'Selected Client is Active Now';
      }

      const response = await axios.put(`${BASE_API_URL}/api/client/updateflag/${_id}`, {isActive : row.original.isActive}, 
        {
          headers: {
            Authorization: `${validToken}`
          }
        }
      );

      switch (response.status) {
        case 200:
          responseSeverity = 'success';
          responseMessage = `${msg}`;

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
  
          setOpen(true);
          handleReload();
          break;
        case 400:
          responseSeverity = 'error';
          responseMessage = 'Update failed: Invalid data!';

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
        case 404:
          responseSeverity = 'error';
          responseMessage = 'Update failed: Client not found!';

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
        case 500:
          responseSeverity = 'error';
          responseMessage = 'Update failed: Internal server error!';
          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
        default:
          responseSeverity = 'error';
          responseMessage = 'Update failed: Unknown error!';

          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          break;
      }
    } catch (error) {
      responseSeverity = 'error';
      responseMessage = 'Error while calling toggleIsActive';
      setProcessing(false);
      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setOpen(true);
    }finally{
      setProcessing(false);
    }
  } 

  /* on click close msg */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  /* column to be export */
  const clientExportedColumns = {
    clientName: 'Client Name',
    pinCode: 'Pin Code',
    state: 'State',
    city: 'City',
    address: 'Client Address',
    contactPersonName: 'Contact Person',
    contactPersonMobile: 'Mobile',
    contactPersonEmail: 'Email'
  };

  /* export all db data */
  const handleExportAllData = async () => {
    setProcessing(true);
    try {
      const validToken = localStorage.getItem('token');
      const response = await axios.get(`${BASE_API_URL}/api/client/exportallclients`, {
        headers: {
          Authorization: `${validToken}`
        }
      });
      let data = response.data;
      exportToExcel(data, 'client.xlsx', clientExportedColumns, 'CLIENT');
  
      setProcessing(false);
    } catch (error) {
      console.error('Error while calling handleExportAllData', error);
    } finally {
      setProcessing(false);
    }
  };

  
  /* export page data */
  const handleExportPageData = (rows) => {
    const rowData = rows.map((row) => row.original);
    exportToExcel(rowData, 'client.xlsx', clientExportedColumns, 'CLIENT');
  }

  /* file selection */
  const handleSelectCSVFile = (event) => {
    debugger
    const file = event.target.files[0];
    if (file) {
      setProcessing(true);
      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        complete: (result) => {
          console.log(result.data);
          const processedData = result.data.slice(1).map(row => ({
            clientName: row[0],
            address: row[1],
            city: row[2],
            state: row[3],
            pinCode: row[4],
            contactPersonName: row[5],
            contactPersonMobile: row[6],
            contactPersonEmail: row[7]
          }));
         
          setClientCsvList(processedData);
          
          setIsCsvReady(true);
          setProcessing(false);
          setIsModalOpen(true);
        },
      });
    }
  }

  /* upload client csv data to database */
  const handleClientUpload = async () => {
    setIsModalOpen(false);
    setProcessing(true);
    let responseSeverity = '';
    let responseMessage = '';

    try {
      const validToken = localStorage.getItem('token');
      if(clientCsvList.length > 0){
        let userId = userDtls.user_ID;

        const response = await axios.post(`${BASE_API_URL}/api/client/bulkclientupload`, { userId, clientCsvList }, {
          headers: {
            Authorization: `${validToken}`
          }
        });

        let msg = response.data.message;
        let duplicateData = response.data.duplicateClients;
        let databaseDuplicate = duplicateData.databaseDuplicates || [];
        let csvFileDuplicate = duplicateData.incomingDuplicates || [];

        switch (response.status) {
          case 201:
            responseSeverity = 'success';
            responseMessage = `${msg}`;
            handleReload();

            if (databaseDuplicate.length > 0 || csvFileDuplicate.length > 0) {
              showDuplicateDialog(databaseDuplicate, csvFileDuplicate);
            }
            break;
          case 400:
            responseSeverity = 'error';
            responseMessage = 'Update failed: Invalid data!';
            break;
          case 404:
            responseSeverity = 'error';
            responseMessage = 'Update failed: file not found!';
            break;
          case 500:
            responseSeverity = 'error';
            responseMessage = 'Update failed: Internal server error!';
            break;
          default:
            responseSeverity = 'error';
            responseMessage = 'Update failed: Unknown error!';
            break;
        }

        setProcessing(false);
        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setOpen(true);
      }else{
        responseSeverity = "error";
        responseMessage = "Selected client Csv is empty";
        setProcessing(false);
        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setOpen(true);
      }
    } catch (error) {
      responseSeverity = "error";
      responseMessage = "Error while calling handleClientUpload, please contact with admin";
      setProcessing(false);
      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setOpen(true);
      console.error('Error while calling handleClientUpload',error);
    }
  }

  const showDuplicateDialog = (databaseDuplicates, csvFileDuplicates) => {
    setDuplicateDialogData({ databaseDuplicates, csvFileDuplicates });
    setIsDuplicateDialogOpen(true);
  };

  /* cancel dialog */
  const handleCancel = () => {
    setIsModalOpen(false);
  }

  /* close confirmation dialog */
  const handleCloseCsvConfirmation = () => {
    setIsModalOpen(false);
  }

  /* fetch client call when type client name */
  const fetchClientForAutocompleteBox= async (value) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/client/getclientkbyprifix/${value}`);
      setFilterClients(response.data);
    } catch (error) {
      console.error('Error fetching  client data:', error);
    }
  };

  /* Requied for filter client autocomplete */
  const handleFilterClientInputChange = (event, value) => {
    setFilterClientInputValue(value);
    setFilterData(prevData => ({
      ...prevData,
      client: {
        ...prevData.client,
        value: value,
        error: !value,
        errorMessage: !value ? 'You must enter a client' : ''
      }
    }));
    if (value.length >= 3) {
      fetchClientForAutocompleteBox(value);
    } else {
      setFilterClients([]);
    }
  };

  /* Requied for filter client autocomplete */
  const handleFilterClientAutocompleteChange = (event, selectedItem) => {
    const selectedData = filterClients.find(item => item.clientName === selectedItem);
    
    if (selectedData) {
      setFilterData(prevData => ({
        ...prevData,
        client: {
          ...prevData.client,
          value: selectedData._id,
          error: false
        }
      }));
    } else {
      console.error('Selected Client data not found in clients array');
    }
  };

  const handleFilterSearch = () => {
    fetchClients();
  }
  
  return (
    <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke', overflowX: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10, xl:10}, width: '100%' }}>
        <Card sx={{borderTop: '5px solid black', width: '100%', mb:3, backgroundColor: 'white'}}>
          <CardContent>
            <form onSubmit={handleSubmit} autoComplete='off'>
              <CustomSnackbar open={open} onClose={handleClose} severity={responseSeverity} message={responseMessage} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <StepriseTextField
                    label="Client Name" 
                    name="clientName"
                    size='small'
                    value={clientData.clientName.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      const re = /^[A-Za-z\s]*$/;

                      if (re.test(value)) {
                        handleChangeForClientData(e);
                      }
                    }}
                    error={clientData.clientName.error}
                    helperText={clientData.clientName.error && clientData.clientName.errorMessage}
                    inputProps={{ inputMode: 'text' }}
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>
  
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <Autocomplete
                    size='small'
                    freeSolo
                    required
                    inputValue={pinInputValue}
                    onInputChange={handlePinInputChange}
                    onChange={handlePinAutocompleteChange}
                    options={pins.map((item) => item.pincode)}
                    renderInput={(params) => (
                      <TextField {...params} label="Pincode" variant="outlined" />
                    )}
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      mt: 0,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black !important',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black !important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black !important',
                        },
                      },
                    }}
                  />
                </Grid>
  
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <StepriseTextField  size='small' label="State" name="state" disabled
                    required
                    value={clientData.state.value}
                    onChange={handleChangeForClientData}
                    error={clientData.state.error}
                    helperText={clientData.state.error && clientData.state.errorMessage}
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>
  
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <StepriseTextField  size='small' label="City" name="city" disabled
                    required
                    value={clientData.city.value}
                    onChange={handleChangeForClientData}
                    error={clientData.city.error}
                    helperText={clientData.city.error && clientData.city.errorMessage}
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>
  
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <StepriseTextField  size='small' label="Client Address" name="address"
                    required
                    value={clientData.address.value}
                    onChange={handleChangeForClientData}
                    error={clientData.address.error}
                    helperText={clientData.address.error && clientData.address.errorMessage}
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <StepriseTextField
                    label="Contact Person Name"
                    name="contactPersonName"
                    size='small'
                    value={clientData.contactPersonName.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      const re = /^[A-Za-z\s]*$/; // Regex to allow only alphabetic characters and spaces

                      // Allow only alphabetic characters and spaces
                      if (re.test(value)) {
                        handleChangeForClientData(e);
                      }
                    }}
                    error={clientData.contactPersonName.error}
                    helperText={clientData.contactPersonName.error && clientData.contactPersonName.errorMessage}
                    inputProps={{ inputMode: 'text' }}
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <StepriseTextField
                  size="small"
                  label="Contact Person Mobile"
                  name="contactPersonMobile"
                  value={clientData.contactPersonMobile.value}
                  onChange={(e) => {
                    const value = e.target.value;
                    const re = /^[0-9\b]*$/;

                    // Allow only numeric values and ensure the length is exactly 10
                    if (re.test(value) && value.length <= 10) {
                      handleChangeForClientData(e);
                    }
                  }}
                  error={
                    clientData.contactPersonMobile.value && 
                    (clientData.contactPersonMobile.error || clientData.contactPersonMobile.value.length < 10)
                  }
                  helperText={
                    clientData.contactPersonMobile.value &&
                    ((clientData.contactPersonMobile.error && clientData.contactPersonMobile.errorMessage) || 
                    (clientData.contactPersonMobile.value.length < 10 && "Minimum 10 digits long"))
                  }
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', minLength: 10, maxLength: 10 }}

                  InputLabelProps={{ style: { color: 'black' } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'black',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                  }}
                />
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <StepriseTextField
                    label="Contact Person Email"
                    name="contactPersonEmail"
                    size='small'
                    value={clientData.contactPersonEmail.value}
                    onChange={(e) => {
                      handleChangeForClientData(e);
                    }}
                    onBlur={() => setTouched(prev => ({ ...prev, contactPersonEmail: true }))}
                    error={!isValidEmail(clientData.contactPersonEmail.value) && touched.contactPersonEmail}
                    helperText={
                      !isValidEmail(clientData.contactPersonEmail.value) && touched.contactPersonEmail && "Invalid email address"
                    }
                    inputProps={{inputMode: 'contactPersonEmail', pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',}}
                    InputLabelProps={{ style: { color: 'black' } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  {isSubmitBtn ? (
                    <Button  type="submit" fullWidth variant="contained" color="secondary" startIcon={<SaveIcon />}
                      sx={{
                        backgroundColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      Submit
                    </Button>
                    ) : (
                    <Button  type="submit" variant="contained" fullWidth color="secondary"  startIcon={<EditNoteIcon />}
                      sx={{
                        backgroundColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      Update
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Button variant="contained" onClick={handleReload} fullWidth color="secondary" type="button" startIcon={<RefreshIcon />}
                    sx={{
                      backgroundColor: 'black',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }}
                  >
                    Reload
                  </Button>
                </Grid>
  
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <input
                    style={{ display: 'none' }}
                    id="file-upload-images"
                    type="file"
                    name="prodImages"
                    onChange={handleSelectCSVFile}
                  />
                  <label htmlFor="file-upload-images">
                    <Button variant="outlined"  fullWidth component="span" startIcon={<CloudUploadIcon />} 
                      sx={{
                        color: 'white',
                        backgroundColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      Select CSV file
                    </Button>
                  </label>
                </Grid>
              </Grid>
  
              {/* CLIENT CSV UPLOAD CONFIRMATION */}
              <Dialog open={isModalOpen} onClose={handleCloseCsvConfirmation} >
                <DialogTitle sx={{textAlign: 'center',display: 'flex',flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
                  Confirmation
                </DialogTitle>
  
                <DialogContent>
                  The Client Data has been processed. Do you want to proceed?
                </DialogContent>
  
                <DialogActions>
                  <Button onClick={handleClientUpload} color="success">
                    Yes
                  </Button>
                  <Button onClick={handleCancel} color="error">
                    No
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Add the logic to display the duplicate dialog */}
              {isDuplicateDialogOpen && (
                <Dialog open={isDuplicateDialogOpen} onClose={() => setIsDuplicateDialogOpen(false)}>
                  <DialogTitle>Duplicate Clint Founds</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      The following duplicates were found in the upload:
                    </DialogContentText>
                    {duplicateDialogData?.databaseDuplicates.length > 0 && (
                      <div>
                        <h5>Already exist in our System </h5>
                        <ol>
                          {duplicateDialogData.databaseDuplicates.map((client, index) => (
                            <li key={index}>
                              <span style={{ fontSize: 14}}>Client Name : <span style={{ color: 'red', fontSize: 12}}>{client.clientName}</span></span> 
                              &nbsp;<span style={{ fontSize: 14}}>Address : </span><span style={{ color: 'red', fontSize: 12}}>{client.address}</span>
                            </li>
                          ))}
                        </ol>
                      </div>
                    )}
                    {duplicateDialogData?.csvFileDuplicates.length > 0 && (
                      <div>
                        <h5>Uploading CSV duplicate items</h5>
                        <ol>
                          {duplicateDialogData.csvFileDuplicates.map((client, index) => (
                            <li key={index}>
                              <span style={{ fontSize: 14}}>Client Name : <span style={{ color: 'red', fontSize: 12}}>{client.clientName}</span></span> 
                              &nbsp;<span style={{ fontSize: 14}}>Address : </span><span style={{ color: 'red', fontSize: 12}}>{client.address}</span>
                            </li>
                          ))}
                        </ol>
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setIsDuplicateDialogOpen(false)} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              {/* Rest of your component code */}
            </form>
            <ProcessingDialog open={processing} />
          </CardContent>
        </Card>

        <Card sx={{borderTop: '5px solid black', width: '100%', mb:1, backgroundColor: 'white'}}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <StepriseAutocompleteBox
                  size='small'
                  required
                  freeSolo
                  name="client"
                  inputValue={filterClientInputValue}
                  onInputChange={handleFilterClientInputChange}
                  onChange={handleFilterClientAutocompleteChange}
                  options={filterClients.map((item) => item.clientName)}
                  label="Type atleast 3 character to display the client list"
                />
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}>
                <Button variant="contained" size='large' onClick={handleFilterSearch} fullWidth type="button"
                  startIcon={<SearchIcon />}
                  sx={{
                    backgroundColor: 'black',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  }}
                >
                  
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
  
        <Grid container spacing={3} sx={{mb:4}}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ResponsiveTable sx={{borderTop: '5px solid #9c27b0'}}
              exportAllData={handleExportAllData}
              handleExportSelectedRows={handleExportPageData}
              handleExportRows={handleExportPageData}
              data={clientTbl}
              headerNames={clientTblHeader}
              editAction={handleEdit}
              deleteAction={deleteAction}
              editTooltipTitle="Edit"
              deleteTooltipTitle="Delete"
              editIcon={EditNoteIcon}
              deleteIcon={EditIcon}
              isActive={toggleIsActive}
              isActiveTooltipTitle="Toggle Active"
              showCopyIcon={false}
              showEditIcon={true}
              showToggleIcon={true}
              showDeleteIcon={false}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
  
}
