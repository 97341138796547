import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import ResponsiveTable from '../Components/ResponsiveTable';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ProcessingDialog from '../Components/ProcessingDialog';
import CustomSnackbar from '../Components/CustomSnackbar';
import StepriseTextField from '../Components/StepriseTextField';
import SearchIcon from '@mui/icons-material/Search';
import exportToExcel from "../utils/exportToExcel";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Papa from 'papaparse';


export default function Court() {
    const [userDtls, setUserDtls] = useState([]);
    const[isSubmitBtn, setIsSubmitBtn] = useState(true);
    const [open, setOpen] = useState(false);
    const [responseSeverity, setResponseSeverity] = React.useState('');
    const [responseMessage, setResponseMessage] = React.useState('');
    const [tableData, setTableData] = useState([]);
    const [processing, setProcessing] = useState(false);
    const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const [courtCsvList, setCourtCsvList] = useState([]);
    const [isCsvReady, setIsCsvReady] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [formData, setFormData] = useState({
      _id:{ value: null },
      courtFullName: { value: '', error: false, errorMessage: '' },
      courtShortName: { value: '', error: false, errorMessage: '' },
      courtType: { value: '', error: false, errorMessage: '' },
      city: { value: '', error: false, errorMessage: '' },
      state: { value: '', error: false, errorMessage: '' },
      isActive: { value: 'Y' },
      createdBy: { value: null, error: false, errorMessage: '' },
      createdDate: { value: null, error: false, errorMessage: '' },
      lastModifiedBy: { value: null, error: false, errorMessage: '' },
      lastModifiedDate: { value: null, error: false, errorMessage: '' }
    });

    const [filterData, setFilterData] = useState({
      courtFullName: { value: '', error: false, errorMessage: '' },
      courtType: { value: '', error: false, errorMessage: '' }
    });

    /* header names for the table columns */ 
    const courtHeaders = [
      { accessorKey: 'courtFullName', name: 'Court Full Name'},
      { accessorKey: 'courtShortName', name: 'Court Short Name'},
      { accessorKey: 'courtType', name: 'Type'},
      { accessorKey: 'city', name: 'City'},
      { accessorKey: 'state', name: 'State'}
    ];

    /* page load */
    useEffect(() => {
      const token = localStorage.getItem('token');
      const dcrptData = jwtDecode(token);
      setUserDtls(dcrptData);

      fetchAllCourts();
    }, []);

    /* fetch all courts */
    const fetchAllCourts = async () => {
      setProcessing(true);
      let responseSeverity = '';
      let responseMessage = '';
      let courtFullName = filterData.courtFullName.value ? filterData.courtFullName.value : "NA";
      let courtType = filterData.courtType.value ? filterData.courtType.value : "NA";
      try {
        const response = await axios.get(`${BASE_API_URL}/api/court/getallcourts/${courtFullName}/${courtType}`);
        setTableData(response.data);
        setProcessing(false);
      } catch (error) {
        responseSeverity = 'error';
        responseMessage = 'Something Went Wrong while fetching court';

        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setOpen(true);
      }
    }

    /* input handle change */
    const handleChange = (event) => {
        const { name, value } = event.target;
        let error = false;
        let errorMessage = '';
    
        setFormData((prevData) => ({
            ...prevData,
            [name]: { ...prevData[name], value, error, errorMessage}
        }));
    };
    
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        let error = false;
        let errorMessage = '';
    
        setFilterData((prevData) => ({
            ...prevData,
            [name]: { ...prevData[name], value, error, errorMessage}
        }));
    };

    /* on click submit button */
    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        let responseSeverity = '';
        let responseMessage = '';

        let valid = true;

        if (!formData.courtFullName.value) {
          setFormData((prevData) => ({
            ...prevData,
            courtFullName: { ...prevData.courtFullName, error: true },
          }));
          valid = false;
        } else {
          setFormData((prevData) => ({
            ...prevData,
            courtFullName: { ...prevData.courtFullName, error: false },
          }));
        }

        if (!valid) {
            responseSeverity = 'error';
            responseMessage = 'Please select court name';
            setProcessing(false);
            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
            return;
        } else {
            const finalFormData = {
                _id: formData._id.value,
                courtShortName: formData.courtShortName.value,
                courtFullName: formData.courtFullName.value,
                courtType: formData.courtType.value,
                city: formData.city.value,
                state: formData.state.value,
                isActive: formData.isActive.value,
                createdBy: userDtls.user_ID,
                lastModifiedBy: userDtls.user_ID
            };

            try {
                const response = await axios.post(`${BASE_API_URL}/api/court/create`, finalFormData);
                let data = response.data;
                let msg = data.message;

                if(data.status === 'success'){
                    responseSeverity = 'success';
                    responseMessage = `${msg}`;

                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setProcessing(false);
                    setOpen(true);

                    handleReload();
                }else if(data.status === 'duplicate'){
                    responseSeverity = 'error';
                    responseMessage = `${msg}`;

                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                }else if(data.status === 'error'){
                    responseSeverity = 'error';
                    responseMessage = 'Something Went Wrong';
                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                }
            } catch (error) {
                responseSeverity = 'error';
                responseMessage = 'Something Went Wrong';
                setProcessing(false);
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
            }finally{
                setProcessing(false);
                setResponseSeverity(responseSeverity);
                setResponseMessage(responseMessage);
                setOpen(true);
            }
        }
    };

     /* on click edit icon */
     const handleEdit = (row) => {
        setProcessing(true);
        
        try {
          setFormData((prevData) => {
            
            const updatedData = {
              ...prevData,
              _id: { ...prevData._id, value: row.original._id },
              courtShortName: { ...prevData.courtShortName, value: row.original.courtShortName },
              courtFullName: { ...prevData.courtFullName, value: row.original.courtFullName },
              courtType: { ...prevData.courtType, value: row.original.courtType },
              city: { ...prevData.city, value: row.original.city },
              state: { ...prevData.state, value: row.original.state }
            };
    
            return updatedData;
          });
    
          setIsSubmitBtn(false);
        } catch (error) {
          responseSeverity = 'error';
          responseMessage = 'Error while click on edit icon, please contact admin' ;
    
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setProcessing(false);
          setOpen(true);
        }finally{
          setProcessing(false);
        }
      }

    /* on click toggle icon */
    const toggleIsActive = async(row) => {
        let responseSeverity = '';
        let responseMessage = '';
        let msg = '';
        setProcessing(true);
        let _id = row.original._id;
        const courtShortName = row.original.courtShortName;
        let loginId = userDtls.user_ID;
        const currentIsActive = row.original.isActive;

        try {
            if(currentIsActive === 'Y'){
                row.original.isActive = 'N';
                msg = `Selected Court ${courtShortName} is In-Active Now`;
            }else {
                row.original.isActive = 'Y';
                msg = `Selected Court ${courtShortName} is Active Now`;
            }

            const response = await axios.put(`${BASE_API_URL}/api/court/updateflag/${_id}`, {isActive : row.original.isActive, loginId});

            switch (response.status) {
                case 200:
                    responseSeverity = 'success';
                    responseMessage = `${msg}`;

                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
            
                    setOpen(true);
                    handleReload();
                    break;
                case 400:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Invalid data!';

                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                case 404:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: court not found!';

                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                case 500:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Internal server error!';
                    setProcessing(false);
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
                default:
                    responseSeverity = 'error';
                    responseMessage = 'Update failed: Unknown error!';
        
                    setResponseSeverity(responseSeverity);
                    setResponseMessage(responseMessage);
                    setOpen(true);
                    break;
            }

        } catch (error) {
            responseSeverity = 'error';
            responseMessage = 'Error while calling toggleIsActive';
            setProcessing(false);
            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
        }finally{
            setProcessing(false);
        }
    }

    const handleFilterSearch = () => {
      fetchAllCourts();
    }

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    /* reload the screen */
    const handleReload = () => {
      setFormData({
        _id:{ value: null },
        courtFullName: { value: '', error: false, errorMessage: '' },
        courtShortName: { value: '', error: false, errorMessage: '' },
        courtType: { value: '', error: false, errorMessage: '' },
        city: { value: '', error: false, errorMessage: '' },
        state: { value: '', error: false, errorMessage: '' },
        isActive: { value: 'Y' },
        createdBy: { value: null, error: false, errorMessage: '' },
        createdDate: { value: null, error: false, errorMessage: '' },
        lastModifiedBy: { value: null, error: false, errorMessage: '' },
        lastModifiedDate: { value: null, error: false, errorMessage: '' }
      });

      setIsSubmitBtn(true);

      fetchAllCourts();
    }

    /* column to be export */
    const customColumns = {
      courtFullName: 'Court Full Name',
      courtShortName: 'Court Short Name',
      courtType: 'Court Type',
      city: 'City',
      state: 'State',
    };


    /* CSV export all  data */
    const handleExportAllData = async() => {
      setProcessing(true);
      try {
        let data = tableData;

        exportToExcel(data, 'Court.xlsx', customColumns, 'COURT');

        setProcessing(false);
      } catch (error) {
        console.error('Error while calling handleExportAllData', error);
      }finally{
        setProcessing(false);
      }
    }

    /* CSV export page data */
    const handleExportPageData = (rows) => {
      const rowData = rows.map((row) => row.original);
      exportToExcel(rowData, 'Court.xlsx', customColumns, 'COURT');
      setProcessing(false);
    }


    /* court file selection */
    const handleCourtCSVFile = (event) => {
      debugger;
      const file = event.target.files[0];
      if (file) {
        setProcessing(true);
        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: (result) => {
            console.log(result.data);
            const processedData = result.data.slice(1).map(row => ({
              courtFullName: row[0],
              courtShortName: row[1],
              courtType: row[2],
              city: row[3],
              state: row[4],
              pincode: row[5]
            }));
          
            setCourtCsvList(processedData);
            
            setIsCsvReady(true);
            setProcessing(false);
            setIsModalOpen(true);
          },
        });
      }
    }


    /* cancel dialog */
    const handleCancel = () => {
      setIsModalOpen(false);
    }

    /* close confirmation dialog */
    const handleCloseCsvConfirmation = () => {
      setIsModalOpen(false);
    }

    /* upload court csv data to database */
    const handleClientUpload = async () => {
      setIsModalOpen(false);
      setProcessing(true);
      let responseSeverity = '';
      let responseMessage = '';

      try {
        const validToken = localStorage.getItem('token');
        if(courtCsvList.length > 0){
          let userId = userDtls.user_ID;

          const response = await axios.post(`${BASE_API_URL}/api/court/bulkcourtupload`, { userId, courtCsvList }, {
            headers: {
              Authorization: `${validToken}`
            }
          });

          let msg = response.data.message;

          switch (response.status) {
            case 201:
              responseSeverity = 'success';
              responseMessage = `${msg}`;
              handleReload();
              break;
            case 400:
              responseSeverity = 'error';
              responseMessage = 'Update failed: Invalid data!';
              break;
            case 404:
              responseSeverity = 'error';
              responseMessage = 'Update failed: file not found!';
              break;
            case 500:
              responseSeverity = 'error';
              responseMessage = 'Update failed: Internal server error!';
              break;
            default:
              responseSeverity = 'error';
              responseMessage = 'Update failed: Unknown error!';
              break;
          }

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
        }else{
          responseSeverity = "error";
          responseMessage = "Selected client Csv is empty";
          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
        }
      } catch (error) {
        responseSeverity = "error";
        responseMessage = "Error while calling handleClientUpload, please contact with admin";
        setProcessing(false);
        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setOpen(true);
        console.error('Error while calling handleClientUpload',error);
      }
    }


    return (
        <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10},  width: '100%' }}>
                <Card sx={{borderTop: '5px solid black', width: '100%', mb:3, backgroundColor: 'white'}}>
                    <CardContent>
                      <CustomSnackbar open={open} onClose={handleClose} severity={responseSeverity} message={responseMessage} />

                        <form onSubmit={handleSubmit} autoComplete='off'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                  <StepriseTextField
                                    label="Court Full Name"
                                    name="courtFullName"
                                    size='small'
                                    value={formData.courtFullName.value}
                                    onChange={handleChange}
                                    required
                                    error={formData.courtFullName.error}
                                    helperText={formData.courtFullName.error ? formData.courtFullName.errorMessage : ''}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ style: { color: 'black' } }}
                                    sx={{
                                      mt: 0,
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                        borderColor: 'black',
                                        },
                                        '&:hover fieldset': {
                                        borderColor: 'black',
                                        },
                                        '&.Mui-focused fieldset': {
                                        borderColor: 'black',
                                        },
                                      },
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6} md={2} lg={2}>
                                  <StepriseTextField
                                    label="Court Short Name"
                                    name="courtShortName"
                                    size='small'
                                    value={formData.courtShortName.value}
                                    onChange={handleChange}
                                    error={formData.courtShortName.error}
                                    helperText={formData.courtShortName.error ? formData.courtShortName.errorMessage : ''}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ style: { color: 'black' } }}
                                    sx={{
                                        mt: 0,
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                            borderColor: 'black',
                                            },
                                            '&:hover fieldset': {
                                            borderColor: 'black',
                                            },
                                            '&.Mui-focused fieldset': {
                                            borderColor: 'black',
                                            },
                                        },
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6} md={2} lg={2}>
                                  <StepriseTextField
                                    label="Court Type"
                                    name="courtType"
                                    size='small'
                                    value={formData.courtType.value}
                                    onChange={handleChange}
                                    error={formData.courtType.error}
                                    helperText={formData.courtType.error ? formData.courtType.errorMessage : ''}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ style: { color: 'black' } }}
                                    sx={{
                                        mt: 0,
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                            borderColor: 'black',
                                            },
                                            '&:hover fieldset': {
                                            borderColor: 'black',
                                            },
                                            '&.Mui-focused fieldset': {
                                            borderColor: 'black',
                                            },
                                        },
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6} md={2} lg={2}>
                                  <StepriseTextField
                                    label="City"
                                    name="city"
                                    size='small'
                                    value={formData.city.value}
                                    onChange={handleChange}
                                    error={formData.city.error}
                                    helperText={formData.city.error ? formData.city.errorMessage : ''}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ style: { color: 'black' } }}
                                    sx={{
                                      mt: 0,
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                        borderColor: 'black',
                                        },
                                        '&:hover fieldset': {
                                        borderColor: 'black',
                                        },
                                        '&.Mui-focused fieldset': {
                                        borderColor: 'black',
                                        },
                                      },
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6} md={2} lg={2}>
                                  <StepriseTextField
                                    label="State"
                                    name="state"
                                    size='small'
                                    value={formData.state.value}
                                    onChange={handleChange}
                                    error={formData.state.error}
                                    helperText={formData.state.error ? formData.state.errorMessage : ''}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ style: { color: 'black' } }}
                                    sx={{
                                      mt: 0,
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                        borderColor: 'black',
                                        },
                                        '&:hover fieldset': {
                                        borderColor: 'black',
                                        },
                                        '&.Mui-focused fieldset': {
                                        borderColor: 'black',
                                        },
                                      },
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                  {isSubmitBtn ? (
                                      <Button type="submit" fullWidth variant="contained" color="secondary" startIcon={<SaveIcon />}
                                          sx={{
                                              backgroundColor: 'black',
                                              '&:hover': {
                                              backgroundColor: 'black',
                                              },
                                          }}
                                      >
                                          Submit
                                      </Button>
                                      ) : (
                                      <Button  type="submit" variant="contained" fullWidth color="secondary"  startIcon={<EditNoteIcon />}
                                          sx={{
                                              backgroundColor: 'black',
                                              '&:hover': {
                                              backgroundColor: 'black',
                                              },
                                          }}
                                      >
                                          Update
                                      </Button>
                                  )}
                                </Grid>

                                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                  <input
                                    style={{ display: 'none' }}
                                    id="file-upload-images"
                                    type="file"
                                    name="prodImages"
                                    onChange={handleCourtCSVFile}
                                  />
                                  <label htmlFor="file-upload-images">
                                    <Button variant="outlined"  fullWidth component="span" startIcon={<CloudUploadIcon />} 
                                      sx={{
                                        color: 'white',
                                        backgroundColor: 'black',
                                        '&:hover': {
                                          backgroundColor: 'black',
                                        },
                                      }}
                                    >
                                      Select CSV file
                                    </Button>
                                  </label>
                                </Grid>

                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Button onClick={handleReload} variant="contained" fullWidth color="secondary" type="button" startIcon={<RefreshIcon />}
                                        sx={{
                                            backgroundColor: 'black',
                                            '&:hover': {
                                              backgroundColor: 'black',
                                            },
                                        }}
                                    >
                                        Reload
                                    </Button>
                                </Grid>
                            </Grid>

                            {/* COURT CSV UPLOAD CONFIRMATION */}
                            <Dialog open={isModalOpen} onClose={handleCloseCsvConfirmation} >
                              <DialogTitle sx={{textAlign: 'center',display: 'flex',flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
                                Confirmation
                              </DialogTitle>
                
                              <DialogContent>
                                The Client Data has been processed. Do you want to proceed?
                              </DialogContent>
                
                              <DialogActions>
                                <Button onClick={handleClientUpload} color="success">
                                  Yes
                                </Button>
                                <Button onClick={handleCancel} color="error">
                                  No
                                </Button>
                              </DialogActions>
                            </Dialog>
                        </form>
                        <ProcessingDialog open={processing} />
                    </CardContent>
                </Card>

                <Card sx={{borderTop: '5px solid black', width: '100%', mb:1, backgroundColor: 'white'}}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={11} sm={11} md={11} lg={11}>
                              <StepriseTextField
                                label="Type court full name for like search"
                                name="courtFullName"
                                size='small'
                                value={filterData.courtFullName.value}
                                onChange={handleFilterChange}
                                required
                                error={filterData.courtFullName.error}
                                helperText={filterData.courtFullName.error ? filterData.courtFullName.errorMessage : ''}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ style: { color: 'black' } }}
                                sx={{
                                  mt: 0,
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                    borderColor: 'black',
                                    },
                                    '&:hover fieldset': {
                                    borderColor: 'black',
                                    },
                                    '&.Mui-focused fieldset': {
                                    borderColor: 'black',
                                    },
                                  },
                                }}
                              />
                            </Grid>

                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                <Button variant="contained" size='large' onClick={handleFilterSearch} fullWidth type="button"
                                  startIcon={<SearchIcon />}
                                  sx={{
                                    backgroundColor: 'black',
                                    '&:hover': {
                                    backgroundColor: 'black',
                                    },
                                  }}
                                >
                                
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Grid container spacing={3} sx={{mb:4}}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ResponsiveTable sx={{borderTop: '5px solid #9c27b0'}}
                          exportAllData={handleExportAllData}
                          handleExportSelectedRows={handleExportPageData}
                          handleExportRows={handleExportPageData}
                          data={tableData}
                          headerNames={courtHeaders}
                          editAction={handleEdit}
                          deleteAction={false}
                          editTooltipTitle="Edit"
                          deleteTooltipTitle="Delete"
                          editIcon={EditNoteIcon}
                          deleteIcon={EditIcon}
                          isActive={toggleIsActive}
                          isActiveTooltipTitle="Toggle Active"
                          showCopyIcon={false}
                          showEditIcon={true}
                          showToggleIcon={true}
                          showDeleteIcon={false} 
                          showActionsColumn={true} 
                        />
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
