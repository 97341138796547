import * as XLSX from 'xlsx';

const exportToExcel = (data, filename, columnMapping, screenType) => {
  const processedData = preprocessData(data, columnMapping, screenType);

  const worksheet = XLSX.utils.json_to_sheet(processedData);
  
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const stripHtmlTags = (htmlString) => {
  return htmlString.replace(/<[^>]*>/g, '');
};

/* preprocessing function */
const preprocessData = (data, customColumns = {}, screenType) => {
  if(screenType === 'EMP'){
    return data.map(row => {
        const processedRow = {};
        Object.keys(row).forEach(key => {
            const value = row[key];
            if (customColumns[key]) {
                if (typeof value === 'object' && value !== null) {
                    if (key === 'role') {
                        processedRow[customColumns[key]] = value.roleName;
                    }else if(key === 'section' ){
                        processedRow[customColumns[key]] = value.sectionName;
                    }
                } else {
                    processedRow[customColumns[key]] = value;
                }
            }
        });
        return processedRow;
    });
  }else if(screenType === 'CLIENT'){
    return data.map(row => {
        const processedRow = {};
        Object.keys(row).forEach(key => {
            const value = row[key];
            if (customColumns[key]) {
                if (typeof value === 'object' && value !== null) {
                    
                } else {
                    processedRow[customColumns[key]] = value;
                }
            }
        });
        return processedRow;
    });
  }else if(screenType === 'FILE'){
      return data.map(row => {
          const processedRow = {};
          Object.keys(row).forEach(key => {
              const value = row[key];
              if (customColumns[key]) {
                  if (typeof value === 'object' && value !== null) {
                      if (key === 'client') {
                          processedRow[customColumns[key]] = value.clientName;
                      }else if(key === 'rack' ){
                          processedRow[customColumns[key]] = value.rackNo;
                      }else if(key === 'court' ){
                          processedRow[customColumns[key]] = value.courtShortName;
                      }else if(key === 'department' ){
                          processedRow[customColumns[key]] = value.departmentName;
                      }else if(key === 'feeCategory' ){
                        console.log(key);
                        
                          processedRow[customColumns[key]] = value.feeCatName;
                      }else if(key === 'assignedAdvocate' ){
                          processedRow[customColumns[key]] = value.fullName;
                      }else if(key === 'aor' ){
                          processedRow[customColumns[key]] = value.fullName;
                      }else {
                          processedRow[customColumns[key]] = value[customColumns[key]];
                      }
                  } else if (key === 'caseDetails') {
                      processedRow[customColumns[key]] = stripHtmlTags(value);
                  } else {
                      processedRow[customColumns[key]] = value;
                  }
              }
          });
          return processedRow;
      });
    }else if(screenType === 'TASK' || screenType === 'DWR'){
      return data.map(row => {
          const processedRow = {};
          Object.keys(row).forEach(key => {
              const value = row[key];
              if (customColumns[key]) {
                  if (typeof value === 'object' && value !== null) {
                      if (key === 'taskMst') {
                          processedRow[customColumns[key]] = value.taskName;
                      }else if(key === 'advocate' ){
                          processedRow[customColumns[key]] = value.fullName;
                      }else if(key === 'intern' ){
                          processedRow[customColumns[key]] = value.fullName;
                      }else if(key === 'clerk' ){
                          processedRow[customColumns[key]] = value.fullName;
                      }else if(key === 'otherAdvocate' ){
                          processedRow[customColumns[key]] = value.fullName;
                      }else if(key === 'court' ){
                          processedRow[customColumns[key]] = value.courtShortName;
                      }
                  } else if (key === 'caseDetails') {
                      processedRow[customColumns[key]] = stripHtmlTags(value);
                  } else {
                      processedRow[customColumns[key]] = value;
                  }
              }
          });
          return processedRow;
        });
    }else if(screenType === 'COURT' || screenType === 'RACK' || screenType === 'DEPT' || screenType === 'FEECAT'){
        return data.map(row => {
            const processedRow = {};
            Object.keys(row).forEach(key => {
                const value = row[key];
                if (customColumns[key]) {
                    if (typeof value === 'object' && value !== null) {
                        
                    } else {
                        processedRow[customColumns[key]] = value;
                    }
                }
            });
            return processedRow;
        });
    }
};

export default exportToExcel;
