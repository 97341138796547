import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  OutlinedInput,
  FormHelperText,
  Button,
  Typography,
  IconButton,
  Container,
} from '@mui/material';
import StepriseTextField from '../Components/StepriseTextField';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import StepriseExptTbl from '../Components/StepriseExptTbl';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import { isEmpty, isValidEmail, isValidMobileNumber } from './Validation';
import CustomSnackbar from '../Components/CustomSnackbar';
import ProcessingDialog from '../Components/ProcessingDialog';
import EditNoteIcon from '@mui/icons-material/EditNote';
import dayjs from 'dayjs';
import ResponsiveTable from '../Components/ResponsiveTable';
import {jwtDecode} from 'jwt-decode';
import _default from '@emotion/styled';
import exportToExcel from "../utils/exportToExcel";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const initialColumnVisibility = {
  'empId': true,
  'fullName': true,
  'primaryMobile': true,
  'email': true,
  'role.roleName': true,
  'section.sectionName': true,
};
export default function Employee() {
  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);
  const [touched, setTouched] = useState({
    primaryMobile: false,
    email: false
  });
  const [userDtls, setUserDtls] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isSubmitBtn, setIsSubmitBtn] = useState(true);
  const [responseSeverity, setResponseSeverity] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [roles, setRoles] = useState([]);
  const [roleInputValue, setRoleInputValue] = useState('');
  const [departments, setDepartments] = useState([]);
  const [departmentInputValue, setDepartmentsInputValue] = useState('');
  const [sections, setsections] = useState([]);
  const [sectionInputValue, setSectionInputValue] = useState('');
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  //const truncatedText = text.length > maxLength ? text.substring(0, maxLength) + '...' : text;

  const EMP_API_URL = process.env.REACT_APP_BASE_API_URL;

  const styles = {
    truncate: {
      display: 'inline-block',
      maxWidth: '115px',  // Adjust the width as needed
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      position: 'relative'
    },
    hoverBox: {
      display: isHovered ? 'block' : 'none',
      position: 'absolute',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      padding: '5px',
      whiteSpace: 'nowrap',
      zIndex: 10,
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      transform: 'translateX(-50%)',
      top: '100%', // Position below the truncated text
      left: '50%'
    }
  };

  /** HEADER COLUMN */
  const headerNames = [
    { accessorKey: 'empId', name: 'Emp ID', size: 150 },
    { accessorKey: 'fullName', name: 'Emp Name', size: 150 },
    { accessorKey: 'primaryMobile', name: 'Mobile', size: 150 },
    { accessorKey: 'email', name: 'Email', size: 200 },
    { accessorKey: 'role.roleName', name: 'Role', size: 200 },
    { accessorKey: 'section.sectionName', name: 'Section', size: 200 },
  ];

  /** initialize the form variable */
  const [empFormData, setEmpFormData] = useState({
    _id: { value: '' },
    prevEmpId: { value: '', error: false },
    empId: { value: '', error: false },
    role: { value: '', error: false, errorMessage: 'You must select role' },
    department: { value: '', error: false, errorMessage: 'You must select department' },
    section: { value: '', error: false, errorMessage: 'You must select sector' },
    branches: { value: [], error: false, errorMessage: 'You must select at least one branch' },
    password: { value: '', error: false },
    doj: { value: null, error: false },
    employmentStatus: { value: 'ACT', error: false },
    fullName: { value: '', error: false, errorMessage: 'Please enter full name' },
    dob: { value: null, error: false, errorMessage: 'You must enter DOB' },
    dobCopy: { value: null, error: false },
    dobFileName: { value: null, error: false },
    gender: { value: 'MAL', error: false, errorMessage: 'You must select gender' },
    maritalStatus: { value: 'SNG', error: false, errorMessage: 'You must select marital status' },
    spouseName: { value: '', error: false },
    primaryMobile: { value: '', error: false, errorMessage: 'You must enter Primary mobile', length: 10 },
    email: { value: '', error: false, errorMessage: 'You must enter valid email' },
    pinCode: { value: '', error: false, errorMessage: 'You must enter pin code' },
    city: { value: '', error: false },
    state: { value: '', error: false },
    stateCode: { value: '', error: false },
    address: { value: '', error: false, errorMessage: 'You must enter address' },
    addressCopy: { value: null, error: false },
    addressFileName: { value: null, error: false },
    landmark: { value: '', error: false, errorMessage: 'You must enter landmark' },
    emgncyPersonName: { value: '', error: false, errorMessage: 'You must enter emergency person name', minLength: 5 },
    emgncyContactNo: { value: '', error: false, errorMessage: 'You must enter emergency contact number', minLength: 5 },
    emgncyPersonRlsn: { value: 'FTR', error: false, errorMessage: 'You must enter emergency contact relation' },
    bloodGrp: { value: 'B_PVT', error: false, errorMessage: 'You must select blood group' },
    isCronicDis: { value: 'N', error: false, errorMessage: 'You must select cronic Disease' },
    cronicDisNameAndDes: { value: '', error: false },
    isPrevEmpExist: { value: 'Y', error: false, errorMessage: 'You must select is prev employee details' },
    prevOfrLtrCopy: { value: null, error: false },
    prevOfrLtrFileName: { value: null, error: false },
    salaryCopy: { value: null, error: false },
    salaryFileName: { value: null, error: false },
    rlvCopy: { value: null, error: false },
    rlvFileName: { value: null, error: false },
    ePFNo: { value: '', error: false, errorMessage: 'You must enter is existing ePF A/C number' },
    eSINo: { value: '', error: false, errorMessage: 'You must select is previous employee details' },
    highestEducation: { value: 'GRD', error: false },
    highestEduCertCopy: { value: null, error: false },
    highestEduCertFileName: { value: null, error: false },
    computerCourse: { value: '', error: false },
    computerCourseCopy: { value: null, error: false },
    computerCourseFileName: { value: null, error: false },
    bankName: { value: '', error: false },
    ifscNo: { value: '', error: false },
    bankBranch: { value: '', error: false },
    accountNo: { value: '', error: false },
    cancelledChqCopy: { value: null, error: false },
    cancelledChqFileName: { value: null, error: false },
    isActive: { value: 'Y', error: false },
    createdBy: { value: null, error: false },
    createdDate: { value: null, error: false },
    lastModifiedBy: { value: null, error: false },
    lastModifiedDate: { value: null, error: false }
  });
  


  /** employee data feching api */
  const fetchEmployeeData = () => {
    axios.get(`${EMP_API_URL}/api/emp/getallemp`)
      .then(response => {
        const { data } = response.data;
        setTableData(response.data);
      })
      .catch(error => {
        console.error('Error fetching table data:', error);
      });
  };

  const fetchBranchData = () => {
    axios.get(`${EMP_API_URL}/api/branch/getallbranches`)
      .then(response => {
        const { data } = response.data;
        if (Array.isArray(data)) {
          if (data.length > 0) {
            const finalData = data.map(item => ({
              _id: item._id,
              branchType: item.branchType,
              branchCode: item.branchCode,
              branchName: item.branchName,
            }));
            setEmpFormData((prevEmpFormData) => ({
              ...prevEmpFormData,
              branches: { ...prevEmpFormData.branches, value: finalData }
            }));
          } else {
            console.error('Error: Response data array is empty');
          }
        } else {
          console.error('Error: Response data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching table data:', error);
      });
  };

  /* fetchOptions call when type pinCode */
  const fetchOptions = async (value) => {
    try {
      const response = await axios.get(`${EMP_API_URL}/api/statepinmap/getStatePinMapByPrefix/${value}`);
      setOptions(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  /* fetchRoles call when type Role */
  const fetchRoles = async (value) => {
    try {
      const roleResponse= await axios.get(`${EMP_API_URL}/api/role/getrolesbyprifix/${value}`);
      setRoles(roleResponse.data);
    } catch (error) {
      console.error('Error fetching  Roles data:', error);
    }
  };

  /* fetchSections call when type section */
  const fetchSections = async (value) => {
    try {
      const response = await axios.get(`${EMP_API_URL}/api/section/getsectionsbyprifix/${value}`);
      setsections(response.data);
    } catch (error) {
      console.error('Error fetching  Section data:', error);
    }
  };

  /* Requied for department autocomplete */
  const handleSectionInputChange = (event, value) => {
    setSectionInputValue(value);
    setEmpFormData(prevEmpFormData => ({
      ...prevEmpFormData,
      section: {
        ...prevEmpFormData.section, 
        value: value,
        error: !value,
        errorMessage: !value ? 'You must enter a section' : ''
      }
    }));
    if (value) {
      fetchSections(value);
    } else {
      setsections([]);
    }
  };

  /* Requied for sector autocomplete */
  const handleSectionAutocompleteChange = (event, selectedSection) => {
    const selectedData = sections.find(item => item.sectionName === selectedSection);
    
    if (selectedData) {
      setEmpFormData(prevEmpFormData => ({
        ...prevEmpFormData,
        section: {
          ...prevEmpFormData.section,
          value: selectedData._id,
          sectionName: selectedData.sectionName, 
          error: false
        }
      }));
    } else {
      console.error('Selected Sector data not found in Sections');
    }
  };

  /* fetchRoles call when type Role */
  const fetchDeartments = async (value) => {
    try {
        const deptResponse = await axios.get(`${EMP_API_URL}/api/department/getdepartmentsbyprifix/${value}`);
        setDepartments(deptResponse.data);
    } catch (error) {
        console.error('Error fetching  Department data:', error);
    }
  };

  /* Requied for pincode autocomplete */
  const handleInputChange = (event, value) => {
    setInputValue(value);
    setEmpFormData(prevEmpFormData => ({
        ...prevEmpFormData,
        pinCode: {
            ...prevEmpFormData.pinCode,
            value: value,
            error: !value,
            errorMessage: !value ? 'You must enter a pincode' : ''
        }
    }));
    if (value) {
        fetchOptions(value);
    } else {
        setOptions([]);
    }
  };

  /* Requied for role autocomplete */
  const handleRoleInputChange = (event, value) => {
    setRoleInputValue(value); // Only set the input value, which is a string (roleName)
    
    setEmpFormData(prevEmpFormData => ({
      ...prevEmpFormData,
      role: {
        ...prevEmpFormData.role,
        value: value, // This is the role name, not an object
        error: !value,
        errorMessage: !value ? 'You must enter a role' : ''
      }
    }));
  
    if (value) {
      fetchRoles(value);
    } else {
      setRoles([]);
    }
  };


  /* Requied for department autocomplete */
  const handleDepartmentInputChange = (event, value) => {
    setDepartmentsInputValue(value);
    setEmpFormData(prevEmpFormData => ({
        ...prevEmpFormData,
        department: {
            ...prevEmpFormData._id,
            value: value,
            error: !value,
            errorMessage: !value ? 'You must enter a department' : ''
        }
    }));
    if (value) {
      fetchDeartments(value);
    } else {
        setDepartments([]);
    }
  };

  /* Requied for pincode autocomplete */
  const handleAutocompleteChange = (event, selectedPincode) => {
    const selectedOption = options.find(option => option.pincode === selectedPincode);
    
    if (selectedOption) {
      setEmpFormData(prevEmpFormData => ({
        ...prevEmpFormData,
        pinCode: {
          ...prevEmpFormData.pinCode,
          value: selectedOption.pincode,
          error: false
        },
        city: {
          ...prevEmpFormData.city,
          value: selectedOption.district
        },
        state: {
          ...prevEmpFormData.state,
          value: selectedOption.stateName
        },
        stateCode: {
          ...prevEmpFormData.stateCode,
          value: selectedOption.stateCode 
        }
      }));
    } else {
      console.error('Selected pincode data not found in options');
    }
  };

  /* Requied for role autocomplete */
  const handleRoleAutocompleteChange = (event, selectedRole) => {
    const selectedData = roles.find(role => role.roleName === selectedRole); // Find role by roleName
  
    if (selectedData) {
      setEmpFormData(prevEmpFormData => ({
        ...prevEmpFormData,
        role: {
          ...prevEmpFormData.role,
          value: selectedData._id, // Store only the role ID in the form
          roleName: selectedData.roleName,
          error: false
        }
      }));
    } else {
      console.error('Selected role not found in roles');
    }
  };
  


  /* Requied for departments autocomplete */
  const handleDepartmentAutocompleteChange = (event, selectedDept) => {
    const selectedData = departments.find(dept => dept.departmentName === selectedDept);
    
    if (selectedData) {
      setEmpFormData(prevEmpFormData => ({
        ...prevEmpFormData,
        department: {
          ...prevEmpFormData.department,
          value: selectedData._id,
          error: false
        }
      }));
    } else {
      console.error('Selected Department data not found in Departments');
    }
  };


  /** handle input change **/
  const handleChange1 = (event) => {
    const { name, value } = event.target;
    let error = false;
    let errorMessage = '';

    const isEmpty = (str) => !str.trim().length;
    const isMobileField = name === 'primaryMobile' || name === 'emgncyContactNo';
    const isConditionalField = (field) =>
      (field === 'spouseName' && empFormData.maritalStatus.value === 'MRD') ||
      (field === 'cronicDisNameAndDes' && empFormData.isCronicDis.value === 'Y') ||
      ((field === 'ePFNo' || field === 'eSINo') && empFormData.isPrevEmpExist.value === 'Y');

    if (isEmpty(value)) {
      if (name === 'fullName') errorMessage = 'You must enter full name';
      if (name === 'dob') errorMessage = 'You must enter date of birth';
      if (name === 'email') errorMessage = 'You must enter an email';
      if (name === 'address') errorMessage = 'Please enter address';
      if (name === 'landmark') errorMessage = 'Please enter landmark';
      if (name === 'emgncyPersonName') errorMessage = 'Please enter emergency contact person name';
      if (isConditionalField(name)) errorMessage = `You must enter ${name.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
      if (['bankName', 'ifscNo', 'bankBranch', 'accountNo'].includes(name)) errorMessage = `Please enter ${name.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
    }

    if (!errorMessage && name === 'email' && !isValidEmail(value)) {
      errorMessage = 'Invalid email address format';
    }

    if (!errorMessage && isMobileField) {
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 10);
      event.target.value = sanitizedValue;
      if (!isValidMobileNumber(sanitizedValue)) errorMessage = 'Invalid mobile number. Number must be 10 digits.';
    }

    if (name === 'branches') {
      setSelectedBranches(value);
      setEmpFormData((prevEmpFormData) => ({
        ...prevEmpFormData,
        branches: { ...prevEmpFormData.branches, error: value.length === 0 },
      }));
    } else {
      setEmpFormData((prevEmpFormData) => ({
        ...prevEmpFormData,
        [name]: { ...prevEmpFormData[name], value, error: !!errorMessage, errorMessage },
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let error = false;
    let errorMessage = '';

    setEmpFormData((prevEmpFormData) => ({
      ...prevEmpFormData,
      [name]: { ...prevEmpFormData[name], value, error, errorMessage}
    }));
  };

  /** date change handler */
  const handleDateChange = (name, value) => {
    let error = false;
    let errorMessage = '';
  
    // Perform validation based on field name
    if (name === 'dob') {
      // Check if the value is empty or not a valid date
      if (!value) {
        error = true;
        errorMessage = 'You must enter a valid date for DOB Issued Date';
      } else if (isFutureDate(value)) {
        error = true;
        errorMessage = 'DOB Date cannot be a future date';
      }
    }
  
    setEmpFormData((prevEmpFormData) => ({
      ...prevEmpFormData,
      [name]: {
        value,
        error,
        errorMessage,
      },
    }));
  };

  const isFutureDate = (date) => {
    return new Date(date) > new Date();
  };

  
  useEffect(() => {
    const token = localStorage.getItem('token');
    const dcrptData = jwtDecode(token);

    setUserDtls(dcrptData);
    fetchEmployeeData();
  }, []);

  /** for msg cancel */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  
  /** reset the form after any operation */
  const handleReload = () => {
    setProcessing(true);
    setEmpFormData({
      _id: { value: '' },
      prevEmpId: { value: '', error: false },
      empId: { value: '', error: false },
      role: { value: '', error: false, errorMessage: 'You must select role' },
      department: { value: '', error: false, errorMessage: 'You must select department' },
      section: { value: '', error: false, errorMessage: 'You must select sector' },
      branches: { value: [], error: false, errorMessage: 'You must select at least one branch' },
      password: { value: '', error: false },
      doj: { value: null, error: false },
      employmentStatus: { value: 'ACT', error: false },
      fullName: { value: '', error: false, errorMessage: 'Please enter full name' },
      dob: { value: null, error: false, errorMessage: 'You must enter DOB' },
      dobCopy: { value: null, error: false },
      dobFileName: { value: null, error: false },
      gender: { value: 'MAL', error: false, errorMessage: 'You must select gender' },
      maritalStatus: { value: 'SNG', error: false, errorMessage: 'You must select marital status' },
      spouseName: { value: '', error: false },
      primaryMobile: { value: '', error: false, errorMessage: 'You must enter Primary mobile', length: 10 },
      email: { value: '', error: false, errorMessage: 'You must enter valid email' },
      pinCode: { value: '', error: false, errorMessage: 'You must enter pin code' },
      city: { value: '', error: false },
      state: { value: '', error: false },
      stateCode: { value: '', error: false },
      address: { value: '', error: false, errorMessage: 'You must enter address' },
      addressCopy: { value: null, error: false },
      addressFileName: { value: null, error: false },
      landmark: { value: '', error: false, errorMessage: 'You must enter landmark' },
      emgncyPersonName: { value: '', error: false, errorMessage: 'You must enter emergency person name', minLength: 5 },
      emgncyContactNo: { value: '', error: false, errorMessage: 'You must enter emergency contact number', minLength: 5 },
      emgncyPersonRlsn: { value: 'FTR', error: false, errorMessage: 'You must enter emergency contact relation' },
      bloodGrp: { value: 'B_PVT', error: false, errorMessage: 'You must select blood group' },
      isCronicDis: { value: 'N', error: false, errorMessage: 'You must select cronic Disease' },
      cronicDisNameAndDes: { value: '', error: false },
      isPrevEmpExist: { value: 'Y', error: false, errorMessage: 'You must select is prev employee details' },
      prevOfrLtrCopy: { value: null, error: false },
      prevOfrLtrFileName: { value: null, error: false },
      salaryCopy: { value: null, error: false },
      salaryFileName: { value: null, error: false },
      rlvCopy: { value: null, error: false },
      rlvFileName: { value: null, error: false },
      ePFNo: { value: '', error: false, errorMessage: 'You must enter is existing ePF A/C number' },
      eSINo: { value: '', error: false, errorMessage: 'You must select is previous employee details' },
      highestEducation: { value: 'GRD', error: false },
      highestEduCertCopy: { value: null, error: false },
      highestEduCertFileName: { value: null, error: false },
      computerCourse: { value: '', error: false },
      computerCourseCopy: { value: null, error: false },
      computerCourseFileName: { value: null, error: false },
      bankName: { value: '', error: false },
      ifscNo: { value: '', error: false },
      bankBranch: { value: '', error: false },
      accountNo: { value: '', error: false },
      cancelledChqCopy: { value: null, error: false },
      cancelledChqFileName: { value: null, error: false },
      isActive: { value: 'Y', error: false },
      createdBy: { value: null, error: false },
      createdDate: { value: null, error: false },
      lastModifiedBy: { value: null, error: false },
      lastModifiedDate: { value: null, error: false }
    });

    setInputValue(''); // Clear the autocomplete input value
    setOptions([]); // Clear the autocomplete options list

    setRoleInputValue('');
    setRoles([]);

    setDepartmentsInputValue('');
    setDepartments([]);

    setSectionInputValue('');
    setsections([]);

    setSelectedBranches([]);
    //fetchBranchData();
    fetchEmployeeData();
    setIsSubmitBtn(true);
    setProcessing(false);
  }

  /* validation for client data */
  const validateEmpData = () => {
    let responseSeverity = '';
    let responseMessage = '';

    const requiredFields = ['fullName', 'dob', 'gender', 'primaryMobile', 'email', 
      'pinCode', 'city', 'state', 'stateCode', 'address','doj', 'role', 'section'
    ];

    let isValid = true;
  
    requiredFields.forEach(field => {
      if (!empFormData[field].value) {
        setEmpFormData(prevState => ({
          ...prevState,
          [field]: { ...prevState[field], error: true },
        }));
        responseSeverity = 'error';
        responseMessage = `${field} can not be blank`;

        setProcessing(false);
        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setOpen(true);

        isValid = false;
      }
    });
  
    return isValid;
  };

  /* on click submit button */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    let responseSeverity = '';
    let responseMessage = '';

    if(validateEmpData()){
      let loginId = userDtls.user_ID;
      try {
        let finalDataToSend = {
          _id: empFormData._id.value || '',
          empId: empFormData.empId.value || '',
          role: empFormData.role.value || null,
          department: empFormData.department.value || null,
          section: empFormData.section.value || null,
          branches: null,
          password: '',
          doj: empFormData.doj.value || null,
          employmentStatus: empFormData.employmentStatus.value || null,
          fullName: empFormData.fullName.value || null,
          dob: empFormData.dob.value || null,
          dobCopy: '',
          dobFileName: '',
          gender: empFormData.gender.value || '',
          maritalStatus: empFormData.maritalStatus.value,
          spouseName: '',
          primaryMobile: empFormData.primaryMobile.value,
          email: empFormData.email.value,
          pinCode: empFormData.pinCode.value,
          city: empFormData.city.value,
          state: empFormData.state.value,
          stateCode: empFormData.stateCode.value,
          address: empFormData.address.value,
          addressCopy: '',
          addressFileName: '',
          landmark: empFormData.landmark.value ? empFormData.landmark.value : '',
          emgncyPersonName: empFormData.emgncyPersonName.value ? empFormData.emgncyPersonName.value : '',
          emgncyContactNo: empFormData.emgncyContactNo.value ? empFormData.emgncyContactNo.value : '',
          emgncyPersonRlsn: '',
          bloodGrp: '',
          isCronicDis: '',
          cronicDisNameAndDes: '',
          isPrevEmpExist: '',
          prevOfrLtrCopy: '',
          prevOfrLtrFileName: '',
          salaryCopy: '',
          salaryFileName: '',
          rlvCopy: '',
          rlvFileName: '',
          ePFNo: '',
          eSINo: '',
          highestEducation: empFormData.highestEducation.value ? empFormData.highestEducation.value : '',
          highestEduCertCopy: '',
          highestEduCertFileName: '',
          computerCourse: '',
          computerCourseCopy: '',
          computerCourseFileName: '',
          bankName: empFormData.bankName.value ? empFormData.bankName.value : '',
          ifscNo: empFormData.ifscNo.value ? empFormData.ifscNo.value : '',
          bankBranch: empFormData.bankBranch.value ? empFormData.bankBranch.value : '',
          accountNo: empFormData.accountNo.value ? empFormData.accountNo.value : '',
          cancelledChqCopy: '',
          cancelledChqFileName: '',
          isActive: empFormData.isActive.value ? empFormData.isActive.value : 'Y',
          createdBy: loginId,
          lastModifiedBy: loginId,
        }

        const response = await axios.post(`${EMP_API_URL}/api/emp/register`, finalDataToSend);

        let data = response.data;
        let msg = data.message;

        if(data.status === 'success'){
          responseSeverity = 'success';
          responseMessage = `${msg}`;

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);

          handleReload();
        }else if(data.status === 'duplicate'){
          responseSeverity = 'error';
          responseMessage = `${msg}`;

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          return;
        }else{
          responseSeverity = 'error';
          responseMessage = `${msg}`;

          setProcessing(false);
          setResponseSeverity(responseSeverity);
          setResponseMessage(responseMessage);
          setOpen(true);
          return;
        }
      } catch (error) {
        responseSeverity = 'error';
        responseMessage = 'Something Went Wrong';
        setProcessing(false);
        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        
        console.error("Error ", error)
      }

      setProcessing(false);
      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      setOpen(true);
      
    }else{
      setProcessing(false);
    }
  }

  const handleDelete = (row) => {
    try {
      
    } catch (error) {
      
    }
  }

  const handleEdit = (row) => {
    try {
      setProcessing(true);
  
      if (row && row.original) {
        const updatedEmpFormData = { ...empFormData };
  
        // Update selected branches
        //setSelectedBranches(row.original.branches || []);
        //setSelectedBranches(row.original.branches.map(branch => branch._id));
  
        Object.keys(empFormData).forEach((key) => {
          let value = row.original[key];
  
          if (['dob', 'doj','createdDate', 'lastModifiedDate'].includes(key)) {
            value = value ? dayjs(value) : null;
          }
  
          if (key === 'pinCode' && row.original[key] !== undefined) {
            value = row.original[key];
            setInputValue(value);
            setOptions([{ pincode: value }]);
          }
  
          if (key === 'role' && row.original[key] !== undefined) {
            //value = row.original[key];
            //setRoleInputValue(value.roleName);
            //setRoles([{ roleName: value }]);

            const roleValue = row.original[key];
            setRoleInputValue(roleValue.roleName);
            setRoles([{ ...roleValue }]);
          }
  
          if (key === 'section' && row.original[key] !== undefined) {
            const sectionValue = row.original[key];
            setSectionInputValue(sectionValue.sectionName);
            setsections([{ ...sectionValue }]);
          }
  
          if (row.original[key] !== undefined) {
            updatedEmpFormData[key] = {
              ...empFormData[key],
              value: value,
            };
          }
  
          if (key === 'dobCopy' || key === 'addressCopy' || key === 'salaryCopy' || key === 'rlvCopy' || key === 'prevOfrLtrCopy' || key === 'highestEduCertCopy' || key === 'computerCourseCopy' || key === 'cancelledChqCopy') {
            if (value) {
              const fileName = value.split('/').pop(); // Extract filename from the path
              updatedEmpFormData[key] = {
                ...empFormData[key],
                value: fileName, // Set the file name only
                path: value // Store the file path for download
              };
            }
          }
        });
  
        setEmpFormData(updatedEmpFormData);
  
        setIsSubmitBtn(false);
        setProcessing(false);
      }
    } catch (error) {
      // Handle error
      console.error("Error in handleEdit:", error);
      setProcessing(false); // Ensure to set processing to false on error
    }
  };
  


  const isEmployeeActive = async (row) => {
    setProcessing(true);
    let responseSeverity = '';
    let responseMessage = '';
    let msg = '';
    const empId = row.original.empId;
    const currentIsActive = row.original.isActive;

    try {
      if(currentIsActive === 'Y'){
        row.original.isActive = 'N';
        msg = 'Selected Employee is In-Active Now';
      }else {
        row.original.isActive = 'Y';
        msg = 'Selected Employee is Active Now';
      }

      if(empId){
        const response = await axios.put(`${EMP_API_URL}/api/emp/updateIsActive/${empId}`, {isActive : row.original.isActive});

        switch (response.status) {
          case 200:
            responseSeverity = 'success';
            responseMessage = `${msg}`;

            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);

            setOpen(true);
            handleReload();
            break;
          case 400:
            responseSeverity = 'error';
            responseMessage = 'Update failed: Invalid data!';
            setProcessing(false);
            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
            break;
          case 404:
            responseSeverity = 'error';
            responseMessage = 'Update failed: Vendor not found!';
            setProcessing(false);
            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
            break;
          case 500:
            responseSeverity = 'error';
            responseMessage = 'Update failed: Internal server error!';
            setProcessing(false);
            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
            break;
          default:
            responseSeverity = 'error';
            responseMessage = 'Update failed: Unknown error!';
            setProcessing(false);
            setResponseSeverity(responseSeverity);
            setResponseMessage(responseMessage);
            setOpen(true);
            break;
        }
      }else{
        responseSeverity = 'error';
        responseMessage = 'Update failed: Something went wrong while calling update function!';
        setProcessing(false);
        setResponseSeverity(responseSeverity);
        setResponseMessage(responseMessage);
        setOpen(true);
        
      }
    } catch (error) {
      responseSeverity = 'error';
      responseMessage = 'Update failed: Something Went Wrong !';
      setProcessing(false);
      setResponseSeverity(responseSeverity);
      setResponseMessage(responseMessage);
      console.error("Error:", error);
    }
  };

  /* column to be export */
  const customColumns = {
    empId: 'Emp ID',
    fullName: 'Full Name',
    role: 'Role',
    section: 'Section',
    primaryMobile: 'Mobile',
    email: 'Email',
    doj: 'DOJ',
    dob: 'DOB',
    gender: 'Gender',
    pinCode: 'Pin',
    city: 'City',
    state: 'State',
    stateCode: 'State Code',
    address: 'Address',
    landmark: 'Landmark',
    emgncyPersonName: 'Emgcy. Contact Person',
    emgncyContactNo: 'Emgcy. Contact No',
    highestEducation: 'Highest Edu.',
    bankName: 'Bank',
    accountNo: 'Account No',
    ifscNo: 'IFSC',
    bankBranch: 'Bank Branch',
    isActive: 'Status'
  };

  const handleExportAllData = async() => {
    setProcessing(true);
    try {
      const validToken = localStorage.getItem('token');
      const response = await axios.get(`${EMP_API_URL}/api/emp/exportallemp`, {
        headers: {
          Authorization: `${validToken}`
        }
      });
      let data = response.data;

      exportToExcel(data, 'Employee.xlsx', customColumns, 'EMP');

      setProcessing(false);
    } catch (error) {
      console.error('Error while calling handleExportAllData', error);
    }finally{
      setProcessing(false);
    }
  }

  /* CSV export page data */
  const handleExportPageData = (rows) => {
    //const rowData = rows.map((row) => row.original);
    //exportToExcel(rowData, 'File.xlsx', customColumns, 'FILE');
    //setProcessing(false);
  }

  return (
    <Container maxWidth="xl" sx={{ px: 1, backgroundColor: 'whitesmoke',  overflowX: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt:{xs: 9, sm: 10, md: 10, lg:10},  width: '100%' }}>
          <Card sx={{borderTop: '5px solid black', width: '100%', mb:3, backgroundColor: 'white'}}>
            <CardContent>
              <form onSubmit={handleSubmit} autoComplete='off'>
                <Grid container spacing={2}>
                  <CustomSnackbar open={open} onClose={handleClose} severity={responseSeverity} message={responseMessage} />
                  {/** first row */}
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <StepriseTextField  size='small' label="Full Name" name="fullName" required
                      value={empFormData.fullName.value}
                      onChange={(e) => {
                        const value = e.target.value;
                        const re = /^[A-Za-z\s]*$/;
  
                        if (re.test(value)) {
                          handleChange(e);
                        }
                      }}
                      error={empFormData.fullName.error}
                      helperText={empFormData.fullName.error && empFormData.fullName.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker sx={{
                          '& .MuiOutlinedInput-input': {
                            height: '0.5em !important', 
                            padding: '16.5px 10px !important'
                          }, 
                          '& .MuiInputLabel-root': {
                            lineHeight: '1.0 !important'
                          },
                          '& .MuiIconButton-root': {
                            color:'#353839 !important'
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#353839 !important',
                            },
                            '&:hover fieldset': {
                              borderColor: '#353839 !important',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#353839 !important',
                            },
                            '&.Mui-error fieldset': {
                              borderColor: 'red',
                            },
                          },

                          '& .MuiInputLabel-root': {
                            color: '#353839 !important', 
                            '&.Mui-focused': {
                              color: '#353839 !important',
                            },
                            '&.Mui-error': {
                              color: 'red', 
                            },
                          },
                        }}
                        label="Date of Birth"
                        name="dob"
                        fullWidth
                        required
                        value={empFormData.dob.value}
                        onChange={(newValue) => handleDateChange('dob', newValue)}
                        error={empFormData.dob.error}
                        helperText={empFormData.dob.error && empFormData.dob.errorMessage}
                        shouldDisableDate={(date) => isFutureDate(date)}
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={4} md={2} lg={2}>
                    <FormControl fullWidth>
                        <InputLabel>Gender</InputLabel>
                        <Select size='small' label="Gender" name='gender' required
                          value={empFormData.gender.value}
                          onChange={handleChange}
                          InputLabelProps={{ style: { color: 'black' } }}
                          sx={{
                            mt: 0,
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'black !important',
                              },
                              '&:hover fieldset': {
                                borderColor: 'black !important',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: 'black !important',
                              },
                            },
                            '&.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root' : {
                              color: 'black !important'
                            },
                            '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused ' : {
                              color: 'black !important'
                            },
                            '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root': {
                              color: 'black !important'
                            }
                          }}
                        >
                          <MenuItem value='MAL'>Male</MenuItem>
                          <MenuItem value='FEM'>Female</MenuItem>
                          <MenuItem value='OTH'>Others</MenuItem>
                        </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} md={2} lg={2}>
                    <FormControl fullWidth>
                        <InputLabel>Marital Status</InputLabel>
                        <Select size='small' label="Marital Status" name='maritalStatus'
                          value={empFormData.maritalStatus.value}
                          onChange={handleChange}
                        >
                          <MenuItem value='SNG'>Single</MenuItem>
                          <MenuItem value='MRD'>Married</MenuItem>
                          <MenuItem value='DVC'>Divorced</MenuItem>
                        </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <StepriseTextField  size='small' label="Primary Mobile" name="primaryMobile" required
                      value={empFormData.primaryMobile.value}
                      onChange={(e) => {
                        const value = e.target.value;
                        const re = /^[0-9\b]*$/;
    
                        // Allow only numeric values and ensure the length is exactly 10
                        if (re.test(value) && value.length <= 10) {
                          handleChange(e);
                        }
                      }}
                      error={
                        empFormData.primaryMobile.value && 
                        (empFormData.primaryMobile.error || empFormData.primaryMobile.value.length < 10)
                      }
                      helperText={
                        empFormData.primaryMobile.value &&
                        ((empFormData.primaryMobile.error && empFormData.primaryMobile.errorMessage) || 
                        (empFormData.primaryMobile.value.length < 10 && "Minimum 10 digits long"))
                      }
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', minLength: 10, maxLength: 10 }}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <StepriseTextField  size='small' label="Email" name="email" required
                      value={empFormData.email.value}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={() => setTouched(prev => ({ ...prev, email: true }))}
                      error={!isValidEmail(empFormData.email.value) && touched.email}
                      helperText={
                        !isValidEmail(empFormData.email.value) && touched.email && "Invalid email address"
                      }
                      inputProps={{inputMode: 'email', pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',}}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Autocomplete
                      size='small'
                      freeSolo
                      required
                      inputValue={inputValue}
                      onInputChange={handleInputChange}
                      onChange={handleAutocompleteChange}
                      options={options.map((option) => option.pincode)}
                      renderInput={(params) => (
                        <TextField {...params} label="Pincode" variant="outlined" />
                      )}

                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <StepriseTextField  size='small' label="City" name="city" disabled
                      required
                      value={empFormData.city.value}
                      onChange={handleChange}
                      error={empFormData.city.error}
                      helperText={empFormData.city.error && empFormData.city.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <StepriseTextField  size='small' label="State" name="state" disabled
                      required
                      value={empFormData.state.value}
                      onChange={handleChange}
                      error={empFormData.state.error}
                      helperText={empFormData.state.error && empFormData.state.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={1}>
                    <StepriseTextField  size='small' label="State Code" name="stateCode" disabled
                      required
                      value={empFormData.stateCode.value}
                      onChange={handleChange}
                      error={empFormData.stateCode.error}
                      helperText={empFormData.stateCode.error && empFormData.stateCode.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={3}>
                    <StepriseTextField  size='small' label="Landmark" name="landmark"
                      value={empFormData.landmark.value}
                      onChange={handleChange}
                      error={empFormData.landmark.error}
                      helperText={empFormData.landmark.error && empFormData.landmark.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <StepriseTextField  size='small' label="Address" name="address" required
                      value={empFormData.address.value}
                      onChange={handleChange}
                      error={empFormData.address.error}
                      helperText={empFormData.address.error && empFormData.address.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <StepriseTextField  size='small' label="Emergency Contact Person" name="emgncyPersonName"
                      value={empFormData.emgncyPersonName.value}
                      onChange={handleChange}
                      error={empFormData.emgncyPersonName.error}
                      helperText={empFormData.emgncyPersonName.error && empFormData.emgncyPersonName.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <StepriseTextField  size='small' label="Emergency Contact No" name="emgncyContactNo"
                      value={empFormData.emgncyContactNo.value}
                      onChange={handleChange}
                      error={empFormData.emgncyContactNo.error}
                      helperText={empFormData.emgncyContactNo.error && empFormData.emgncyContactNo.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  {/*
                  
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <FormControl fullWidth>
                        <InputLabel>Relation</InputLabel>
                        <Select size='small' label="Relation" name='emgncyPersonRlsn'
                          value={empFormData.emgncyPersonRlsn.value}
                          onChange={handleChange}
                        >
                          <MenuItem value='MTR'>Mother</MenuItem>
                          <MenuItem value='FTR'>Father</MenuItem>
                          <MenuItem value='BRT'>Brother</MenuItem>
                          <MenuItem value='SIS'>Sister</MenuItem>
                          <MenuItem value='ANT'>Aunty</MenuItem>
                          <MenuItem value='UNC'>Uncle</MenuItem>
                          <MenuItem value='WFE'>Wife</MenuItem>
                          <MenuItem value='SON'>Son</MenuItem>
                          <MenuItem value='DTR'>Daughter</MenuItem>
                        </Select>
                    </FormControl>
                  </Grid>
                  
                  */}
                  
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <FormControl fullWidth>
                      <InputLabel>Highest Eduction</InputLabel>
                      <Select size='small' label="Highest Eduction" name='highestEducation'
                        value={empFormData.highestEducation.value}
                        onChange={handleChange}
                      >
                        <MenuItem value='B10'>Below 10th</MenuItem>
                        <MenuItem value='10'>10th</MenuItem>
                        <MenuItem value='12'>12th</MenuItem>
                        <MenuItem value='ITI'>ITI</MenuItem>
                        <MenuItem value='DIP'>Diploma</MenuItem>10
                        <MenuItem value='GRD'>Graduation</MenuItem>
                        <MenuItem value='MST'>Masters</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                 
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <StepriseTextField  size='small' label="Bank Name" name="bankName"
                      value={empFormData.bankName.value}
                      onChange={handleChange}
                      error={empFormData.bankName.error}
                      helperText={empFormData.bankName.error && empFormData.bankName.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <StepriseTextField  size='small' label="Account Number" name="accountNo"
                      value={empFormData.accountNo.value}
                      onChange={handleChange}
                      error={empFormData.accountNo.error}
                      helperText={empFormData.accountNo.error && empFormData.accountNo.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <StepriseTextField  size='small' label="IFSC Code" name="ifscNo"
                      value={empFormData.ifscNo.value}
                      onChange={handleChange}
                      error={empFormData.ifscNo.error}
                      helperText={empFormData.ifscNo.error && empFormData.ifscNo.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <StepriseTextField  size='small' label="Bank Branch" name="bankBranch"
                      value={empFormData.bankBranch.value}
                      onChange={handleChange}
                      error={empFormData.bankBranch.error}
                      helperText={empFormData.bankBranch.error && empFormData.bankBranch.errorMessage}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker sx={{
                          '& .MuiOutlinedInput-input': {
                            height: '0.5em !important', 
                            padding: '16.5px 10px !important'
                          }, 
                          '& .MuiInputLabel-root': {
                            lineHeight: '1.0 !important'
                          },
                          '& .MuiIconButton-root': {
                            color:'#353839'
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#353839 !important',
                            },
                            '&:hover fieldset': {
                              borderColor: '#353839 !important',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#353839 !important',
                            },
                            '&.Mui-error fieldset': {
                              borderColor: 'red',
                            },
                          },

                          '& .MuiInputLabel-root': {
                            color: '#353839 !important', 
                            '&.Mui-focused': {
                              color: '#353839 !important',
                            },
                            '&.Mui-error': {
                              color: 'red', 
                            },
                          },
                        }}
                        label="Date of Joining"
                        name="doj"
                        fullWidth
                        value={empFormData.doj.value}
                        onChange={(newValue) => handleDateChange('doj', newValue)}
                        error={empFormData.doj.error}
                        helperText={empFormData.doj.error && empFormData.doj.errorMessage}
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Autocomplete
                      size='small'
                      freeSolo
                      inputValue={roleInputValue}
                      onInputChange={handleRoleInputChange}
                      onChange={(event, newValue) => handleRoleAutocompleteChange(event, newValue)}
                      options={roles.map((role) => role.roleName)}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Role" variant="outlined" />
                      )}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Autocomplete
                      size='small'
                      freeSolo
                      inputValue={sectionInputValue}
                      onInputChange={handleSectionInputChange}
                      onChange={(event, newValue) => handleSectionAutocompleteChange(event, newValue)}
                      options={sections.map((section) => section.sectionName)}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Section" variant="outlined" />
                      )}
                      InputLabelProps={{ style: { color: 'black' } }}
                      sx={{
                        mt: 0,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black !important',
                          },
                          '&:hover fieldset': {
                            borderColor: 'black !important',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black !important',
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    {isSubmitBtn ? (
                      <Button  type="submit" fullWidth variant="contained" color="secondary" startIcon={<SaveIcon />}
                        sx={{
                          backgroundColor: 'black',
                          '&:hover': {
                            backgroundColor: 'black',
                          },
                        }}
                      >
                          Submit
                      </Button>
                      ) : (
                      <Button  type="submit" variant="contained" fullWidth color="secondary"  startIcon={<EditNoteIcon />}
                        sx={{
                          backgroundColor: 'black',
                          '&:hover': {
                            backgroundColor: 'black',
                          },
                        }}
                      >
                          Update
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Button variant="contained" onClick={handleReload} fullWidth  type="button" startIcon={<RefreshIcon />}
                      sx={{
                        backgroundColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      Reload
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <ProcessingDialog open={processing} />
            </CardContent>
          </Card>
          
          <Grid container spacing={3} sx={{mb:4}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/* Table content */}
              {/* 
                <ResponsiveTable
                  data={tableData}
                  headerNames={headerNames}
                  editAction={handleEdit} 
                  deleteAction={handleDelete}
                  editTooltipTitle="EDIT"
                  deleteTooltipTitle="DELETE"
                  editIcon={EditNoteIcon}
                  isActive={isEmployeeActive}
                  showCopyIcon={false}
                  showEditIcon={true}
                  showToggleIcon={true}
                  showDeleteIcon={false} 
                  showActionsColumn={true} 
                />
              */}
                
              {/* End of table content */}
              <ResponsiveTable sx={{borderTop: '5px solid #9c27b0'}}
                exportAllData={handleExportAllData}
                handleExportSelectedRows={handleExportPageData}
                handleExportRows={handleExportPageData}
                data={tableData}
                headerNames={headerNames}
                editAction={handleEdit}
                copyAction={false}
                deleteAction={handleDelete}
                editTooltipTitle="Edit"
                copyTooltipTitle="Copy"
                deleteTooltipTitle="Delete"
                editIcon={EditNoteIcon}
                copyIcon={ContentCopyIcon}
                deleteIcon={EditIcon}
                isActive={isEmployeeActive}
                isActiveTooltipTitle="Toggle Active"
                initialState={{ columnVisibility }}
                showCopyIcon={false}
                showEditIcon={true}
                showToggleIcon={true}
                showDeleteIcon={false} 
              />
            </Grid>
          </Grid>
      </Box>
    </Container>
  );
};
